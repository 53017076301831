import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import cartographyConfig from '@configs/cartographyConfig'
import Icon from '@mdi/react';
import { deepCopy } from '@utils'

const initialState = {
    drawingOptions: {
        mapCamera: {
            center: { lat: 37.8025259, lng: -122.4351431 },
            zoom: 10,
            userLocation: null,
            mapType: 'satellite'
        },
        strokeColor: 'rgba(16,15,15,1)',
        strokeWidth: 1,
        fillColor: 'rgba(180,15,15,1)',
        opacity: 1,
        shape: 'polyline',
        labelHidden: false
    },
    editing: null,
    creatingHole: false,
    deletingHole: false,
    deletePolygonHole: null,
    movePinsEnable: false,
    pinIndex: null,
    zones: {},
    zoneMedia: {},
    zonesComments: {},
    projectMedia: {},
    projectInfo: null,
    isStarted: false,
    isOpenLockScreen: false,
    projectCount: 0,
    projectUsers: [],
    archivedZones: {},
    mapsNonce:null,
    openInfoBox:false,
    ediZoneId:null
}

export const cartographySlice = createSlice({
    name: 'cartography',
    initialState,
    reducers: {
        setProjectCount: (state, action) => {
            state.projectCount= action.payload
        },
        setProjectStatus: (state, action) => {
            state.isStarted= action.payload
        },
        setArchiveZones: (state, action) => {
            state.archivedZones = action.payload
        },
        setEditingCoordinates: (state, action) => {
            if(state.drawingOptions.shape==='spot'){
                return;
            }
            let coords = JSON.parse(JSON.stringify(state.editing?.zone_coordinates ?? []))
            coords.push(action.payload)
            state.editing = { ...state.editing, shape: state.drawingOptions.shape, zone_coordinates: coords }
        },
        updateProjectInfo: (state, action) => {
            state.projectInfo= action.payload
            // state.movi
        },
        updateEditing: (state, action) => {
            state.drawingOptions.mapCamera.userLocation=null
            state.editing = action.payload
        },
        
        handleMapCamera: (state, action) => {
            state.drawingOptions.mapCamera = action.payload
        },

        handleShapes: (state, action) => {
            state.editing = null
            state.drawingOptions.shape = action.payload
            toast.success(<div className='d-flex flex-row align-items-center'><Icon path={cartographyConfig.SHAPES[action.payload].icon} size={1} color='#de2929' /><span className='ml-1'>{cartographyConfig.SHAPES[action.payload].label} mode</span></div>,{
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        },

        handleDrawingOptions: (state, action) => {
            state.drawingOptions= action.payload
        },
        saveZone: (state, action) => {
            let zonesArr = deepCopy(state.zones)
            zonesArr[action.payload.zone_id]= action.payload
            state.zones = zonesArr
            // state.drawingMode=false
            state.creatingHole=false
            state.editing=null
        },

        initNewProject: (state, action) => {
            state.projectUsers = []
            state.projectInfo=null
            state.zones= {}
            state.zoneMedia={}
            state.zonesComments={}
            state.projectMedia={},
            state.editing= null
            state.isStarted= false
            state.creatingHole=false
            state.drawingOptions.mapCamera.userLocation=null
            state.movePinsEnable=false
            state.pinIndex=null
            state.archivedZones = {}
        },

        handleHoleMode: (state, action) => {
            state.creatingHole = action.payload.hole
            state.editing= action.payload.editing
            state.deletingHole = false
            state.movePinsEnable = false
        },
        handleDelteingHoleMode: (state, action) => {
            state.deletingHole = action.payload.deletingHole
            state.creatingHole = false
            state.movePinsEnable = false
        },
        handleMovePins: (state, action) => {
            state.movePinsEnable = action.payload.movePinsEnable
            state.creatingHole = false
            state.deletingHole = false
        },
        
        handlePinIndex: (state, action) => {
            state.pinIndex = action.payload
        },
        emptyEditing: (state) => {
            state.editing=null
            state.creatingHole=false
            state.movePinsEnable=false
            state.pinIndex=null
            state.drawingOptions.mapCamera.userLocation=null
        },
        handleProjectEdit: (state, action) => {
            const { zones, info, media, projectMedia, projectUsers, archivedZones }= action.payload
            state.projectUsers = projectUsers
            state.projectInfo = info
            state.zones = zones
            state.zoneMedia=media
            state.projectMedia=projectMedia,
            state.zonesComments={},
            state.editing=null
            state.isStarted= false
            state.creatingHole=false
            state.deletingHole = false
            state.drawingOptions.mapCamera.userLocation=null
            state.movePinsEnable=false
            state.pinIndex=null
            state.archivedZones = archivedZones
        },
        setZoneMedia: (state, action) => {
            state.zoneMedia = action.payload
        },
        setZoneComments: (state, action) => {
            state.zonesComments = action.payload
        },
        updateZones: (state, action) => {
            state.zones = action.payload
        },
        updateZonesKey: (state, action) => {
            let zonesArr = deepCopy(state.zones)
            zonesArr[action.payload.zone_id][action.payload.key] = action.payload.value
            state.zones = zonesArr
            // state.drawingMode=false
            state.creatingHole=false
            state.editing=null
        },
        setProjectMedia: (state, action) => {
            state.projectMedia= action.payload
        },
        updateCommentStatus: (state, action) => {
            let _comm= deepCopy(state.zonesComments??{})
            _comm[action.payload.local_id].status= action.payload.status
            state.zonesComments = _comm
        },
        updateZoneMediaStatus: (state, action) => {
            const { id, status, uri } = action.payload
            let _media= deepCopy(state.zoneMedia)
            _media[id].uri= uri ?? _media[id].uri
            _media[id].uploading = status
            _media[id].isLocal = status == 3 ? false : true
            state.zoneMedia = _media
        },
        updateProjectMediaStatus: (state, action) => {
            const { id, status, uri } = action.payload
            let _media= deepCopy(state.projectMedia)
            _media[id].uri= uri ?? _media[id].uri
            _media[id].uploading = status
            _media[id].isLocal = status == 3 ? false : true
            state.projectMedia = _media
        },
        setMapsNonce:(state,action)=>{
            state.mapsNonce = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setArchiveZones,
    handleShapes,
    setProjectStatus,
    handleHoleMode,
    handleDelteingHoleMode,
    updateProjectInfo,
    handleMapCamera,
    handleEditing,
    handlePinIndex,
    saveZone,
    handleDrawingOptions,
    initNewProject,
    updateEditing,
    setEditingCoordinates,
    emptyEditing,
    handleProjectEdit,
    setZoneComments,
    updateCommentStatus,
    setZoneMedia,
    updateZones,
    updateZoneMediaStatus,
    setProjectMedia,
    updateProjectMediaStatus,
    deletingHole,
    handleMovePins,
    setProjectCount,
    deletePolygonHole,updateZonesKey,
    setMapsNonce } = cartographySlice.actions

export default cartographySlice.reducer